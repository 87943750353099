@import '../../../styles/var';

.inputField {
    position: relative;
}

.input {
    width: 100%;
    height: 46px;
    padding: 0 12px;
    border: 1px solid #2F2F2F;
    border-radius: 10px;
    background: #2F2F2F;

    color: #fff;
    font-family: $roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;

    @media(max-width: $md6+px) {
        font-size: 14px;
    }

    &::placeholder {
        color: rgba($color: #fff, $alpha: 0.6);
    }

    &:focus {
        background: rgba($color: #2F2F2F, $alpha: 0.8);
    }

    &.error {
        border-color: red;
    }
}

.phone {
    padding: 0;
    border: none;
    text-align: left !important;

    &.containerClass {}

    .inputClass {
        width: 100% !important;
        height: 46px !important;
        padding: 0 12px;
        border: 1px solid #2F2F2F !important;
        border-radius: 10px !important;
        background: #2F2F2F !important;

        color: #fff !important;
        font-family: $roboto;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 150% !important;

        @media(max-width: $md6+px) {
            font-size: 14px !important;
        }

        &.error {
            border-color: red !important;
        }

        &:focus {
            background: rgba($color: #2F2F2F, $alpha: 0.8) !important;
        }
    }

    .buttonClass {
        background: transparent !important;
        border: none !important;

        &:hover {
            background: transparent;
        }

        :global(.selected-flag) {
            transition: all .1s;
            border-radius: 10px 0 0 10px !important;

            &:hover,
            &.open {
                background: transparent !important;
            }

            &:global(.open) {
                background: transparent !important;
            }
        }
    }

    .dropdownClass {
        color: #000 !important;
    }

    .searchClass {
        width: 100% !important;
        padding: 5px !important;

        input {
            border: none !important;
            width: 100%;
            margin-left: 0 !important;
        }
    }
}