@import '../../../styles/var';

.wrap {
    position: relative;

    .title {

        .ttl_01 {
            display: inline-flex;
            padding-right: 5px;
            transform: rotate(-7deg);
        }

        .ttl_02 {
            display: inline-flex;
        }
    }

    .cards {
        margin-top: 60px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);
        gap: 20px;

        @media(max-width: $md4+px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }

        @media(max-width: $md6+px) {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .card {
            border-radius: 20px;
            background: #2B2B2B;
            padding: 35px 20px 35px;
            text-align: center;

            @media(max-width: $md6+px) {
                padding: 20px;
            }

            .icon {
                width: 60px;

                @media(max-width: $md4+px) {
                    width: 50px;
                }

                @media(max-width: $md6+px) {
                    width: 40px;
                }
            }

            .text {
                margin-top: 20px;

                @media(max-width: $md6+px) {
                    margin-top: 10px;
                }
            }
        }
    }
}