@import '../../../styles/var';

.container {
    position: relative;

    .paint {
        position: absolute;
        z-index: 2;

        @media(max-width: $md4+px) {
            display: none;
        }

        &_01 {
            top: 60px;
            left: 0;
            width: 353px;

            @media(max-width: $md3+px) {
                width: 300px;
                top: 100px;
            }   
        }

        &_02 {
            top: -100px;
            right: 0;
            width: 398px;

            @media(max-width: $md3+px) {
                width: 310px;
                top: -30px;
            }
        }
    }
}

.wrap {
    position: relative;
    z-index: 5;

    .title {
        text-align: center;
    }

    .subtitle {
        text-align: center;
        margin: 20px 0 40px;

        @media(max-width: $md6+px) {
            margin-top: 12px;
        }
    }

    .formWrap {
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
    }
}