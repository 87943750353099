@import "../../../styles/var";

.button {
    position: relative;
    display: inline-flex;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    overflow: hidden;

    padding: 14px 50px;
    background: #FCD74F;
    border-radius: 60px;

    font-family: $roboto;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #000;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 18px;
    }

    @media (max-width: $md6+px) {
        font-size: 16px;
    }

    &:hover {
        opacity: 0.7;
    }

    &:active {
        transform: scale(0.96);
        transition: all 0.1s;
    }

    &.dark {
        background: #000;
        color: #fff;
    }
}