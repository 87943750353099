@import '../../../styles/var';

.formWrap {
    position: relative;

    .status {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        text-align: center;
        left: 0;
        top: 0;
        transform: scale(0.7);
        opacity: 0;
        pointer-events: none;
        transition: all .3s;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 20px 20px;

        &Error,
        &Success {
            z-index: 3;
        }

        &Process {
            z-index: 2;
        }

        &Icon {
            width: 50px;
            height: 50px;
        }

        &Text {
            color: #fff;
            margin-top: 15px;
            letter-spacing: 0.5px;
        }


        &.active {
            transform: scale(1);
            opacity: 1;
            pointer-events: visible;

        }
    }

    .form {
        position: relative;
        z-index: 1;

        &.blur {
            filter: blur(5px);
        }

        .inputs {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 12px 20px;

            @media(max-width: 700px) {
                display: flex;
                flex-direction: column;
            }
        }

        .check {
            display: grid;
            grid-template-columns: 16px 1fr;
            align-items: center;
            gap: 15px;
            margin: 15px 0 30px;

            &.incorrect {
                .checkText {
                    color: red !important;
                }
            }

            &Box {
                position: relative;
                border: 2px solid #2F2F2F;
                width: 16px;
                height: 16px;
                cursor: pointer;
                transition: all .1s linear;

                &Active {
                    &::before {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        width: 8px;
                        height: 8px;
                        background: #2F2F2F;
                    }
                }
            }

            &Text {
                color: #fff;
                text-align: left;
                line-height: 120%;
                font-size: 14px;

                a {
                    font-weight: 400;
                    color: #fff;
                    text-decoration: underline;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }

        .submit {
            text-align: center;
        }
    }
}